/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic';
import AppointmentBookingFormSchema from '@amplience/content-types/src/content-schemas/c-appointment-booking-form.json';

/* eslint-disable promise/prefer-await-to-then */
const AppointmentBookingForm = dynamic(() =>
  import('./appointmentBookingForm/AppointmentBookingForm').then((mod) => mod.AppointmentBookingForm),
);
/* eslint-enable promise/prefer-await-to-then */

export function createAppointmentAmplienceComponent<T extends unknown[]>(array: T): any {
  const components: any = {};
  array?.forEach((comp) => {
    const componentProps = comp as any;

    if (!componentProps?._meta) {
      return;
    }
    if (componentProps._meta.schema === AppointmentBookingFormSchema.$id) {
      components[AppointmentBookingFormSchema.$id] = AppointmentBookingForm;
    }
  });

  return components;
}
