/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic';
import BannerSchema from '@amplience/content-types/src/content-schemas/c-banner.json';
import CategoryGridItemSchema from '@amplience/content-types/src/content-schemas/c-category-grid-item.json';
import CategoryNavigationCardSchema from '@amplience/content-types/src/content-schemas/c-category-navigation-card.json';
import CheckoutConfirmationWhatHappenNextSchema from '@amplience/content-types/src/content-schemas/c-checkout-confirmation-what-happen-next.json';
import ContentBlockSchema from '@amplience/content-types/src/content-schemas/c-content-block.json';
import ContentBlocksSchema from '@amplience/content-types/src/content-schemas/c-content-blocks.json';
import ContentProductCardSchema from '@amplience/content-types/src/content-schemas/c-content-product-card.json';
import CookiePolicyTableSchema from '@amplience/content-types/src/content-schemas/c-cookie-policy-table.json';
import CopyCTASchema from '@amplience/content-types/src/content-schemas/c-copy-cta.json';
import CTAButtonsSchema from '@amplience/content-types/src/content-schemas/c-cta-buttons.json';
import EditorialQuoteSchema from '@amplience/content-types/src/content-schemas/c-editorial-quote.json';
import FullWidthCarouselSchema from '@amplience/content-types/src/content-schemas/c-full-width-carousel.json';
import GDPRDoNotSellPageSchema from '@amplience/content-types/src/content-schemas/c-gdpr-page.json';
import HeroBannerSchema from '@amplience/content-types/src/content-schemas/c-hero-banner.json';
import HistoryCardsCarouselItemSchema from '@amplience/content-types/src/content-schemas/c-history-cards-carousel-item.json';
import HistoryCardsSchema from '@amplience/content-types/src/content-schemas/c-history-cards.json';
import HygienePageRichTextSchema from '@amplience/content-types/src/content-schemas/c-hygiene-page-rich-text.json';
import IconAndCTASchema from '@amplience/content-types/src/content-schemas/c-icon-&-cta.json';
import ImageAccordionSchema from '@amplience/content-types/src/content-schemas/c-image-accordion.json';
import ImageGridComponentSchema from '@amplience/content-types/src/content-schemas/c-image-grid-component.json';
import MeasureFitSchema from '@amplience/content-types/src/content-schemas/c-measure-fit.json';
import NeedHelpSectionSchema from '@amplience/content-types/src/content-schemas/c-need-help-section.json';
import OtherSimilarLinksSchema from '@amplience/content-types/src/content-schemas/c-other-similar-links.json';
import ParagraphTextSchema from '@amplience/content-types/src/content-schemas/c-paragraph-text.json';
import ProductConstructionSchema from '@amplience/content-types/src/content-schemas/c-product-construction.json';
import SelectorSliderSchema from '@amplience/content-types/src/content-schemas/c-selector-slider.json';
import TwoColumnAccordionsSchema from '@amplience/content-types/src/content-schemas/c-two-column-accordions.json';
import TypographyCTASchema from '@amplience/content-types/src/content-schemas/c-typography-cta.json';
import UnidaysIntegrationSchema from '@amplience/content-types/src/content-schemas/c-unidays-integration.json';
import UspBarSchema from '@amplience/content-types/src/content-schemas/c-usp-bar.json';
import BreadcrumbNodeSchema from '@amplience/content-types/src/content-schemas/h-breadcrumb-node.json';
import BreadcrumbSchema from '@amplience/content-types/src/content-schemas/h-breadcrumb.json';
import HTMLLayoutSchema from '@amplience/content-types/src/content-schemas/s-html-layout.json';
import HygienePageLayoutSchema from '@amplience/content-types/src/content-schemas/s-hygiene-page-layout.json';
import Page404LayoutSchema from '@amplience/content-types/src/content-schemas/s-page-404-layout.json';
import StoreLocatorLayoutSchema from '@amplience/content-types/src/content-schemas/s-store-locator-layout.json';

/* eslint-disable promise/prefer-await-to-then */

const HygienePageLayout = dynamic(() =>
  import('./hygienePages/HygienePageLayout').then((mod) => mod.HygienePageLayout),
);

const ContentBreadcrumb = dynamic(() =>
  import('./contentBreadcrumb/ContentBreadcrumb').then((mod) => mod.ContentBreadcrumb),
);

const Error404PageLayout = dynamic(() => import('./error404/Error404PageLayout').then((mod) => mod.Error404PageLayout));

const Banner = dynamic(() => import('./banner/Banner').then((mod) => mod.Banner));

const UspBar = dynamic(() => import('./uspBar/UspBar').then((mod) => mod.UspBar));

const CopyCTA = dynamic(() => import('./copyCta/CopyCTA').then((mod) => mod.CopyCTA));

const HeroBanner = dynamic(() => import('./homepage/HeroBanner').then((mod) => mod.HeroBanner));

const CategoryGridItem = dynamic(() =>
  import('./categoryGridItem/CategoryGridItem').then((mod) => mod.CategoryGridItem),
);

const CTAButtons = dynamic(() => import('./ctaButtons/CTAButtons').then((mod) => mod.CTAButtons));

const TypographyCTA = dynamic(() => import('./typographyCta/TypographyCTA').then((mod) => mod.TypographyCTA));

const HistoryCards = dynamic(() => import('./historyCards/HistoryCards').then((mod) => mod.HistoryCards));

const HistoryCardsCarouselItem = dynamic(() =>
  import('./historyCardsCarouselItem/HistoryCardsCarouselItem').then((mod) => mod.HistoryCardsCarouselItem),
);

const ContentBlocks = dynamic(() => import('./contentBlocks/ContentBlocks').then((mod) => mod.ContentBlocks));

const ContentBlock = dynamic(() => import('./contentBlocks/ContentBlock').then((mod) => mod.ContentBlock));

const SelectorSlider = dynamic(() => import('./selectorSlider/SelectorSlider').then((mod) => mod.SelectorSlider));

const FullWidthCarousel = dynamic(() =>
  import('./fullWidthCarousel/FullWidthCarousel').then((mod) => mod.FullWidthCarousel),
);

const MeasureFit = dynamic(() => import('./measureFit/MeasureFit').then((mod) => mod.MeasureFit));

const NeedHelpSection = dynamic(() => import('./needHelpSection/NeedHelpSection').then((mod) => mod.NeedHelpSection));

const WhatHappensNext = dynamic(() => import('./whatHappenNext/WhatHappensNext').then((mod) => mod.WhatHappensNext));

const OtherSimilarLinks = dynamic(() =>
  import('./hygienePages/OtherSimilarLinks').then((mod) => mod.OtherSimilarLinks),
);

const HygienePageRichText = dynamic(() =>
  import('./hygienePages/HygienePageRichText').then((mod) => mod.HygienePageRichText),
);

const EditorialQuote = dynamic(() => import('./editorialQuote/EditorialQuote').then((mod) => mod.EditorialQuote));

const ImageGridComponent = dynamic(() =>
  import('./imageGridComponent/ImageGridComponent').then((mod) => mod.ImageGridComponent),
);

const ContentProductCard = dynamic(() =>
  import('./contentProductCard/ContentProductCard').then((mod) => mod.ContentProductCard),
);

const ImageAccordions = dynamic(() => import('./imageAccordion/ImageAccordion').then((mod) => mod.ImageAccordions));

const CategoryNavigationCardBlock = dynamic(() =>
  import('./categoryNavigationCard/CategoryNavigationCardBlock').then((mod) => mod.CategoryNavigationCardBlock),
);

const ParagraphText = dynamic(() => import('./paragraphText/ParagraphText').then((mod) => mod.ParagraphText));

const ProductConstruction = dynamic(() =>
  import('./productConstruction/ProductConstruction').then((mod) => mod.ProductConstruction),
);

const HTMLContent = dynamic(() => import('./htmlContentComponent/HTMLContent').then((mod) => mod.HTMLContent));

const GDPRDoNotSellPage = dynamic(() => import('./gdpr/GDPRDoNotSellPage').then((mod) => mod.GDPRDoNotSellPage));

const StoreLocatorPageLayout = dynamic(() =>
  import('./storeLocatorPage/StoreLocatorPageLayout').then((mod) => mod.StoreLocatorPageLayout),
);

const ContentBreadcrumbNode = dynamic(() =>
  import('./contentBreadcrumb/ContentBreadcrumbNode').then((mod) => mod.ContentBreadcrumbNode),
);

const CookiePolicyTable = dynamic(() =>
  import('./cookiePolicyTable/CookiePolicyTable').then((mod) => mod.CookiePolicyTable),
);

const TwoColumnAccordions = dynamic(() =>
  import('./twoColumnAccordions/TwoColumnAccordions').then((mod) => mod.TwoColumnAccordions),
);

const IconAndCta = dynamic(() => import('./hygienePages/IconAndCta').then((mod) => mod.IconAndCta));

const UnidaysIntegration = dynamic(() =>
  import('./unidaysIntegration/UnidaysIntegration').then((mod) => mod.UnidaysIntegration),
);

/* eslint-enable promise/prefer-await-to-then */

export function createContentAmplienceComponent<T extends unknown[]>(array: T): any {
  const components: any = {};
  array?.forEach((comp) => {
    const componentProps = comp as any;

    if (!componentProps?._meta) {
      return;
    }

    if (componentProps._meta.schema === BannerSchema.$id) {
      components[BannerSchema.$id] = Banner;
    } else if (componentProps._meta.schema === UspBarSchema.$id) {
      components[UspBarSchema.$id] = UspBar;
    } else if (componentProps._meta.schema === CopyCTASchema.$id) {
      components[CopyCTASchema.$id] = CopyCTA;
    } else if (componentProps._meta.schema === HeroBannerSchema.$id) {
      components[HeroBannerSchema.$id] = HeroBanner;
    } else if (componentProps._meta.schema === CategoryGridItemSchema.$id) {
      components[CategoryGridItemSchema.$id] = CategoryGridItem;
    } else if (componentProps._meta.schema === CTAButtonsSchema.$id) {
      components[CTAButtonsSchema.$id] = CTAButtons;
    } else if (componentProps._meta.schema === TypographyCTASchema.$id) {
      components[TypographyCTASchema.$id] = TypographyCTA;
    } else if (componentProps._meta.schema === HistoryCardsSchema.$id) {
      components[HistoryCardsSchema.$id] = HistoryCards;
    } else if (componentProps._meta.schema === HistoryCardsCarouselItemSchema.$id) {
      components[HistoryCardsCarouselItemSchema.$id] = HistoryCardsCarouselItem;
    } else if (componentProps._meta.schema === ContentBlocksSchema.$id) {
      components[ContentBlocksSchema.$id] = ContentBlocks;
    } else if (componentProps._meta.schema === ContentBlockSchema.$id) {
      components[ContentBlockSchema.$id] = ContentBlock;
    } else if (componentProps._meta.schema === SelectorSliderSchema.$id) {
      components[SelectorSliderSchema.$id] = SelectorSlider;
    } else if (componentProps._meta.schema === FullWidthCarouselSchema.$id) {
      components[FullWidthCarouselSchema.$id] = FullWidthCarousel;
    } else if (componentProps._meta.schema === MeasureFitSchema.$id) {
      components[MeasureFitSchema.$id] = MeasureFit;
    } else if (componentProps._meta.schema === NeedHelpSectionSchema.$id) {
      components[NeedHelpSectionSchema.$id] = NeedHelpSection;
    } else if (componentProps._meta.schema === CheckoutConfirmationWhatHappenNextSchema.$id) {
      components[CheckoutConfirmationWhatHappenNextSchema.$id] = WhatHappensNext;
    } else if (componentProps._meta.schema === OtherSimilarLinksSchema.$id) {
      components[OtherSimilarLinksSchema.$id] = OtherSimilarLinks;
    } else if (componentProps._meta.schema === HygienePageLayoutSchema.$id) {
      components[HygienePageLayoutSchema.$id] = HygienePageLayout;
    } else if (componentProps._meta.schema === HygienePageRichTextSchema.$id) {
      components[HygienePageRichTextSchema.$id] = HygienePageRichText;
    } else if (componentProps._meta.schema === EditorialQuoteSchema.$id) {
      components[EditorialQuoteSchema.$id] = EditorialQuote;
    } else if (componentProps._meta.schema === ImageGridComponentSchema.$id) {
      components[ImageGridComponentSchema.$id] = ImageGridComponent;
    } else if (componentProps._meta.schema === ContentProductCardSchema.$id) {
      components[ContentProductCardSchema.$id] = ContentProductCard;
    } else if (componentProps._meta.schema === ImageAccordionSchema.$id) {
      components[ImageAccordionSchema.$id] = ImageAccordions;
    } else if (componentProps._meta.schema === CategoryNavigationCardSchema.$id) {
      components[CategoryNavigationCardSchema.$id] = CategoryNavigationCardBlock;
    } else if (componentProps._meta.schema === ParagraphTextSchema.$id) {
      components[ParagraphTextSchema.$id] = ParagraphText;
    } else if (componentProps._meta.schema === ProductConstructionSchema.$id) {
      components[ProductConstructionSchema.$id] = ProductConstruction;
    } else if (componentProps._meta.schema === HTMLLayoutSchema.$id) {
      components[HTMLLayoutSchema.$id] = HTMLContent;
    } else if (componentProps._meta.schema === GDPRDoNotSellPageSchema.$id) {
      components[GDPRDoNotSellPageSchema.$id] = GDPRDoNotSellPage;
    } else if (componentProps._meta.schema === StoreLocatorLayoutSchema.$id) {
      components[StoreLocatorLayoutSchema.$id] = StoreLocatorPageLayout;
    } else if (componentProps._meta.schema === BreadcrumbSchema.$id) {
      components[BreadcrumbSchema.$id] = ContentBreadcrumb;
    } else if (componentProps._meta.schema === BreadcrumbNodeSchema.$id) {
      components[BreadcrumbNodeSchema.$id] = ContentBreadcrumbNode;
    } else if (componentProps._meta.schema === CookiePolicyTableSchema.$id) {
      components[CookiePolicyTableSchema.$id] = CookiePolicyTable;
    } else if (componentProps._meta.schema === TwoColumnAccordionsSchema.$id) {
      components[TwoColumnAccordionsSchema.$id] = TwoColumnAccordions;
    } else if (componentProps._meta.schema === IconAndCTASchema.$id) {
      components[IconAndCTASchema.$id] = IconAndCta;
    } else if (componentProps._meta.schema === Page404LayoutSchema.$id) {
      components[Page404LayoutSchema.$id] = Error404PageLayout;
    } else if (componentProps._meta.schema === UnidaysIntegrationSchema.$id) {
      components[UnidaysIntegrationSchema.$id] = UnidaysIntegration;
    }
  });

  return components;
}
